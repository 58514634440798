import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);


if(
  window.location.hostname==='kluskaart.be' || 
  window.location.hostname==='mijn-klus-kaart.be' ||
  window.location.hostname==='kluscadeau.be' ||
  window.location.hostname==='mijnbricokaart.be' ||
  window.location.hostname==='mijn-brico-kaart.be' ||
  window.location.hostname==='bricokaart.be' ||
  window.location.hostname==='mijn-diy-kaart.be' ||
  window.location.hostname==='mijndiykaart.be' ||
  window.location.hostname==='mijn-doe-het-zelf-kaart.be' ||
  window.location.hostname==='doe-het-zelf-kaart.be' ||
  window.location.hostname==='mijndoehetzelfkaart.be' ||
  window.location.hostname==='doehetzelfkaart.be' ||
  window.location.hostname==='dhzkaart.be'
  ) 
  {
  document.location.href = 'https://www.kluskaart.be'
  } 
else if(
  window.location.hostname==='cartebricolage.be' || 
  window.location.hostname==='macartebricolage.be' ||
  window.location.hostname==='ma-carte-bricolage.be' ||
  window.location.hostname==='macartebrico.be' ||
  window.location.hostname==='cartebrico.be' ||
  window.location.hostname==='ma-carte-brico.be' ||
  window.location.hostname==='macartecadeaubrico.be' ||
  window.location.hostname==='ma-carte-cadeau-brico.be' ||
  window.location.hostname==='macartecadeaubricolage.be' ||
  window.location.hostname==='ma-carte-cadeau-bricolage.be' ||
  window.location.hostname==='cartebricolage.be' ||
  window.location.hostname==='carte-bricolage.be' ||
  window.location.hostname==='cadeaubricolage.be'
  )
  {
   document.location.href = 'https://www.cartebricolage.be'
  }
  else
  {
    document.location.href = 'https://www.bricocadeau.be'
  }   

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
